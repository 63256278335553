import { AuthenticationProvider } from '@axa-fr/react-oidc-context'
import {
    Alert,
    AlertTitle,
    AlertDescription,
    Box,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import {
    AuthConfiguration,
    permissions,
    useAuthenticatedUser,
} from './Authentication'
import { AdminErrorBoundary } from './ErrorBoundary'
import { Header } from './Header'
import { Menu } from './Menu'
import { Routes } from './routes'
import { environment } from '../environments/environment'

function AuthenticatedApp() {
    const user = useAuthenticatedUser()
    const hasLimitedAccess = !!user?.hasPermission(permissions.callCenter)
    const hasFullAccess = !!user?.hasPermission(permissions.admin)
    const isProduction = environment.production

    return (
        <>
            {isProduction && (
                <Alert status="info" variant="solid" flexDirection="column">
                    <AlertTitle fontSize="md">
                        Production Environment
                    </AlertTitle>
                    <AlertDescription fontSize="sm">
                        You are in the production environment, any changes made
                        will appear in the production app / site
                    </AlertDescription>
                </Alert>
            )}
            <Box mt="10px">
                <Header />

                {!user && (
                    <Box display="flex" flexDir="column" alignItems="center">
                        <Text fontSize="x-large" pt="4">
                            Simple Admin
                        </Text>
                        <Text fontSize="medium" pt="12">
                            Please log in
                        </Text>
                    </Box>
                )}

                {user && (hasLimitedAccess || hasFullAccess) && (
                    <AdminErrorBoundary>
                        <Menu hasFullAccess={hasFullAccess} />
                        <Routes hasFullAccess={hasFullAccess} />
                    </AdminErrorBoundary>
                )}

                {user && !hasLimitedAccess && !hasFullAccess && (
                    <Box
                        pt="20"
                        display="flex"
                        flexDir="column"
                        alignItems="center"
                    >
                        <Text>You are not listed as an admin.</Text>
                        <Text>
                            To request access, please post a request to the{' '}
                            <a href="https://app.slack.com/client/T0H36JY3S/C0JMN32G1">
                                Slack channel and ask for your email address (
                                {user.profile?.email}) to be added.
                            </a>
                        </Text>
                        <Text>
                            You might need to log out and log in again after
                            you've been added to the admin list.
                        </Text>
                    </Box>
                )}
            </Box>
        </>
    )
}

export function App() {
    return (
        <AuthenticationProvider
            configuration={AuthConfiguration}
            callbackComponentOverride={() => <span>Logging In</span>}
            sessionLostComponent={() => <span>Session Lost</span>}
            notAuthenticated={() => <span>Not Authorized</span>}
        >
            <AuthenticatedApp />
        </AuthenticationProvider>
    )
}

export default App
