import React, { useEffect, useState } from 'react'
import { Tabs, Tab, TabList } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export function Menu({ hasFullAccess }: { hasFullAccess: boolean }) {
    const history = useHistory()

    useEffect(() => {
        if (history.location.pathname === '/seasons') {
            if (!hasFullAccess) return
            setTabIndex(0)
        } else if (history.location.pathname === '/search') {
            setTabIndex(1)
        } else {
            setTabIndex(1)
        }
    }, [history.location.pathname, hasFullAccess])

    const [tabIndex, setTabIndex] = useState(1)

    const handleTabsChange = (index: number) => {
        if (!hasFullAccess) return

        setTabIndex(index)
    }

    return (
        <Tabs index={tabIndex} onChange={handleTabsChange} mb={4}>
            <TabList>
                <Tab
                    onClick={() => hasFullAccess && history.push('/seasons')}
                    color={hasFullAccess ? '' : 'lightgrey'}
                >
                    Manage Seasons
                </Tab>
                <Tab onClick={() => history.push('/search')}>User Actions</Tab>
            </TabList>
        </Tabs>
    )
}
