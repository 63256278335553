import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Text, Button } from '@chakra-ui/react'
import { CheckCircleIcon, WarningIcon, StarIcon } from '@chakra-ui/icons'
import { Season } from '../data'
import { CardComponent } from '../components/CardComponent'
import { useCreateExpertCompetition } from '../hooks/useCreateExpertCompetition'

export function ExpertTipperSection({
    season,
    refetch,
}: {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}) {
    const history = useHistory()

    const expertTippingCompId = season.config?.expertTippingCompId

    const [createExpertComp] = useCreateExpertCompetition()

    const onClick = async () => {
        if (!expertTippingCompId) {
            await createExpertComp({ season })
            await refetch()
        }

        history.push(`/seasons/${season.sport}_${season.season}/expert_tippers`)
    }

    return (
        <Box mt="8">
            <Text fontSize={'x-large'}>Expert Tippers</Text>
            <CardComponent
                action={
                    <Button
                        colorScheme="blue"
                        leftIcon={<StarIcon />}
                        onClick={onClick}
                    >
                        {expertTippingCompId ? 'Update' : 'Create'}
                    </Button>
                }
                title="Competition"
                content={
                    expertTippingCompId ? (
                        <Box display="flex" flexDir="row" alignItems="center">
                            <CheckCircleIcon color="green" mr="1" />

                            <Text color="#5C5C5C">{expertTippingCompId}</Text>
                        </Box>
                    ) : (
                        <Box display="flex" flexDir="row" alignItems="center">
                            <WarningIcon color="#D22828" mr="1" />

                            <Text color="#5C5C5C">None Configured</Text>
                        </Box>
                    )
                }
            />
        </Box>
    )
}
