import * as z from 'zod'
import { Team, VenueShape, MatchWinnerShape } from '../api-interfaces'
import { atLeastOneId } from './custom'
import { SeasonShape } from './season'
import { SportShape } from './sport'

const AflScoreShape = z.object({
    goals: z.number(),
    behinds: z.number(),
    total: z.number(),
    type: z.literal('afl-score'),
})

const CricketScoreShape = z.object({
    wickets: z.number(),
    runs: z.number(),
    overs: z.number(),
    sixes: z.number(),
    winner: MatchWinnerShape.optional(),
    type: z.literal('cricket-score'),
    superOver: z
        .object({
            wickets: z.number(),
            runs: z.number(),
        })
        .optional(),
})

const MatchScoreShape = z.union([AflScoreShape, CricketScoreShape])

export type MatchScore = z.infer<typeof MatchScoreShape>
export type AflScore = z.infer<typeof AflScoreShape>
export type CricketScore = z.infer<typeof CricketScoreShape>

export function isAflScore(matchScore?: MatchScore): matchScore is AflScore {
    return matchScore?.type === 'afl-score'
}

export function isCricketScore(
    matchScore?: MatchScore,
): matchScore is CricketScore {
    return matchScore?.type === 'cricket-score'
}

export function zeroedCricketScore(): CricketScore {
    return {
        wickets: 0,
        runs: 0,
        sixes: 0,
        overs: 0,
        type: 'cricket-score',
        superOver: undefined,
    }
}

export function zeroedAflScore(): AflScore {
    return {
        goals: 0,
        behinds: 0,
        total: 0,
        type: 'afl-score',
    }
}

export const WinHistoryShape = z.union([
    z.literal('W'),
    z.literal('D'),
    z.literal('L'),
])

export type WinHistory = z.infer<typeof WinHistoryShape>

export const MatchStatusShape = z.union([
    z.literal('scheduled'),
    z.literal('pre-match'),
    z.literal('in-progress'),
    z.literal('completed'),
    z.literal('cancelled'),
    z.literal('suspended'),
])

export type MatchStatus = z.infer<typeof MatchStatusShape>

export const MatchShape = z
    .object({
        // The ingester may not know our id
        id: z.string().nonempty().optional(),
        // but it will know the external id
        // keeping it optional, because our champion data ingester still
        // doesn't use it
        externalId: z.string().nonempty().optional(),
        round: z.number(),
        matchNumber: z.number().optional(),
        homeTeam: z.nativeEnum(Team),
        homeTeamScore: MatchScoreShape.optional(),
        awayTeam: z.nativeEnum(Team),
        awayTeamScore: MatchScoreShape.optional(),
        venue: VenueShape.optional(),
        startTime: z.string().optional(),
        status: MatchStatusShape,
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export type Match = z.TypeOf<typeof MatchShape>

export const MatchScorelessStatusLifecycleEventShape = z
    .object({
        id: z.string().nonempty().optional(),
        externalId: z.string().nonempty().optional(),
        type: z.literal('match-status-updated-lifecycle-event'),
        status: z.union([
            z.literal('scheduled'),
            z.literal('pre-match'),
            z.literal('cancelled'),
            z.literal('suspended'),
        ]),
        sport: SportShape,
        season: z.string(),
        round: z.number().optional(),
        matchNumber: z.number().optional(),
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export const MatchScoredStatusLifecycleEventShape = z
    .object({
        id: z.string().nonempty().optional(),
        externalId: z.string().nonempty().optional(),
        type: z.literal('match-status-updated-lifecycle-event'),
        status: z.union([
            z.literal('in-progress'),
            z.literal('completed'),
            z.literal('cancelled'),
            z.literal('suspended'),
        ]),
        score: z.object({
            home: MatchScoreShape,
            away: MatchScoreShape,
        }),
        sport: SportShape,
        season: z.string(),
        round: z.number().optional(),
        matchNumber: z.number().optional(),
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export const MatchStatusPlayerStatsLifecycleEventShape = z.object({
    type: z.literal('match-status-player-stats-updated-lifecycle-event'),
    sport: SportShape,
    season: SeasonShape,
    round: z.number(),
    matchExternalId: z.string(),
    matchStatus: z.union([z.literal('in-progress'), z.literal('completed')]),
    playerExternalId: z.string(),
    playerFirstname: z.string(),
    playerSurname: z.string(),
    jumperNumber: z.number(),
    positionName: z.string(),

    kicks: z.number(),
    marks: z.number(),
    handballs: z.number(),
    disposals: z.number(),
    hitouts: z.number(),
    tackles: z.number(),
    freesFor: z.number(),
    freesAgainst: z.number(),
    clangers: z.number(),
    clangerDisposals: z.number(),
    contestedMarks: z.number(),
    uncontestedMarks: z.number(),
    hitoutsToAdvantage: z.number(),
    spoils: z.number(),
    goals: z.number(),
    behinds: z.number(),
    timeOnGroundPercentage: z.number(),
})

export type MatchScorelessStatusLifecycleEvent = z.TypeOf<
    typeof MatchScorelessStatusLifecycleEventShape
>

export type MatchScoredStatusLifecycleEvent = z.TypeOf<
    typeof MatchScoredStatusLifecycleEventShape
>

export type MatchStatusPlayerStatsLifecycleEvent = z.TypeOf<
    typeof MatchStatusPlayerStatsLifecycleEventShape
>

export const MatchStatusLifecycleEventShape = z.union([
    MatchScorelessStatusLifecycleEventShape,
    MatchScoredStatusLifecycleEventShape,
])

export type MatchStatusLifecycleEvent = z.TypeOf<
    typeof MatchStatusLifecycleEventShape
>
