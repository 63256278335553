import * as z from 'zod'

export const SportShape = z.union([
    z.literal('AFL'),
    z.literal('Cricket'),
    z.literal('WAFL'),
])

export const SportSelectionShape = z.union([
    z.literal('AFL'),
    z.literal('Cricket'),
    z.literal('WAFL'),
    z.literal(''),
])

export type SportName = z.TypeOf<typeof SportShape>

export function isSportName(sport: unknown): sport is SportName {
    if (typeof sport !== 'string') {
        return false
    }
    return ['AFL', 'Cricket', 'WAFL'].includes(sport)
}

/**
 * Takes a string that looks like a sport, and converts it to a SportName.
 * Returns undefined if there is no matching sport.
 **/
export function massageSport(rough: string | undefined): SportName | undefined {
    if (!rough) {
        return undefined
    }
    const map: Record<string, SportName> = {
        afl: 'AFL',
        cricket: 'Cricket',
        wafl: 'WAFL',
    }
    return map[rough.toLowerCase()]
}
