import {
    Text,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Button,
    Image,
    Spinner,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { StyledModal } from '../components/StyledModal'
import {
    CompetitionDTO,
    useGetCompetitionsByKeyword,
    usePostAddToCompetition,
} from '../data'
import { useCreateExpertCompetition } from '../hooks/useCreateExpertCompetition'
import { useUpdateExpertMapping } from '../hooks/useUpdateExpertMapping'
import { usePostAvatar } from '../hooks/usePostAvatar'
import { useGetExpertMappings } from '../hooks/useGetExpertMappings'
import { environment } from '../../environments/environment'
import { FileControl } from '../components/FileControl'
import { resizeImage } from '../helpers/image-resizer'
import { ManageSeasonBaseProps } from './ManageSeason'
import { FaUserGraduate } from 'react-icons/fa'

type Member = {
    id: string
    name: string
    username: string
    avatar?: string
    title?: string
}

export function ExpertTippers({ season, refetch }: ManageSeasonBaseProps) {
    const id = season.config?.expertTippingCompId

    const [createExpertComp] = useCreateExpertCompetition()

    const onClick = async () => {
        await createExpertComp({ season })
        refetch()
    }

    if (!id)
        return (
            <Box>
                <Text>No Competition</Text>

                <Button onClick={onClick}>Create the competition</Button>
            </Box>
        )

    return <ManageTippers id={id} />
}

function ManageTippers({ id }: { id: string }) {
    const { data, isLoading, refetch } = useGetCompetitionsByKeyword({
        keyword: id,
    })
    useEffect(() => {
        refetch()
    }, [refetch])

    const {
        data: expertMappings,
        refetch: refetchMappings,
    } = useGetExpertMappings()

    const comp = data?.find((x) => x.pk === id) as CompetitionDTO | undefined

    const [isOpen, setIsOpen] = useState(false)

    if (!comp && isLoading) return <Spinner />
    if (!comp) return <Text>Error fetching competition information</Text>

    const experts: Member[] =
        comp.members
            ?.filter((m) => m.userId !== comp.ownerSub)
            .map((m) => {
                const mapping = expertMappings?.find((x) => x.sub === m.userId)

                return {
                    id: m.userId,
                    name: mapping?.fullName || m.name,
                    username: m.username,
                    avatar: mapping?.avatar,
                    title: mapping?.title,
                }
            }) || []

    const owner = comp.members?.find((m) => m.userId === comp.ownerSub)

    return (
        <Box>
            <Box display="flex" alignItems="end">
                <Text fontSize="xx-large" mt="4" flex="1">
                    Competition {id}
                </Text>
                <Button
                    colorScheme="blue"
                    onClick={() => setIsOpen(true)}
                    rightIcon={<FaUserGraduate />}
                >
                    Add new expert
                </Button>
            </Box>
            <AddMemberModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onConfirm={() => {
                    refetch()
                    setIsOpen(false)
                }}
                id={id}
            />

            <Text fontSize="large" marginTop="8">
                Owner
            </Text>
            <Text marginLeft="5">
                {owner?.name} ({owner?.username})
            </Text>
            <Text fontSize="large" marginTop="8">
                Members
            </Text>
            <TableContainer
                borderColor="blackAlpha.250"
                borderWidth="thin"
                padding="4"
                borderRadius="12"
                mt="4"
                mb="4"
            >
                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Avatar</Th>
                            <Th>Title</Th>
                            <Th>UserName</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {experts.map((member) => {
                            return (
                                <Tr key={member.id}>
                                    <Td>
                                        <NameCell
                                            member={member}
                                            refetchMappings={refetchMappings}
                                        />
                                    </Td>
                                    <Td>
                                        <AvatarCell
                                            member={member}
                                            refetchMappings={refetchMappings}
                                        />
                                    </Td>
                                    <Td>
                                        <TitleCell
                                            member={member}
                                            refetchMappings={refetchMappings}
                                        />
                                    </Td>
                                    <Td>{member.username}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function NameCell({
    member,
    refetchMappings,
}: {
    member: Member
    refetchMappings: () => Promise<any>
}) {
    const [updateExpertMapping] = useUpdateExpertMapping()
    const [showModal, setShowModal] = useState(false)
    const [newName, setNewName] = useState(member.name || '')

    const confirm = async () => {
        await updateExpertMapping({
            userSub: member.id,
            title: member.title || '',
            avatar: member.avatar || '',
            name: newName,
        })
        await refetchMappings()
        setShowModal(false)
    }
    return (
        <>
            <Box
                display="flex"
                width="260px"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text>{member.name}</Text>
                <Button
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => setShowModal(true)}
                >
                    Edit
                </Button>
            </Box>
            <StyledModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                size="lg"
                footer={<Button onClick={confirm}>Update</Button>}
            >
                <Text fontSize="lg" mt="2" mb="4">
                    Change Name ({member.name})
                </Text>
                <Text>Expert's name:</Text>
                <Input
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
            </StyledModal>
        </>
    )
}

function TitleCell({
    member,
    refetchMappings,
}: {
    member: Member
    refetchMappings: () => Promise<any>
}) {
    const [updateExpertMapping] = useUpdateExpertMapping()
    const [showModal, setShowModal] = useState(false)
    const [newTitle, setNewTitle] = useState(member.title || '')

    const confirm = async () => {
        await updateExpertMapping({
            userSub: member.id,
            title: newTitle,
            avatar: member.avatar || '',
            name: member.name,
        })
        await refetchMappings()
        setShowModal(false)
    }
    return (
        <>
            <Box
                display="flex"
                width="260px"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text>{member.title || 'No title'}</Text>
                <Button
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => setShowModal(true)}
                >
                    Edit
                </Button>
            </Box>
            <StyledModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                size="lg"
                footer={<Button onClick={confirm}>Update</Button>}
            >
                <Text fontSize="lg" mt="2" mb="4">
                    Change Title ({member.name})
                </Text>
                <Text>Expert's title:</Text>
                <Input
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                />
            </StyledModal>
        </>
    )
}

function AvatarCell({
    member,
    refetchMappings,
}: {
    member: Member
    refetchMappings: () => Promise<any>
}) {
    const [updateExpertMapping] = useUpdateExpertMapping()
    const [uploadAvatar] = usePostAvatar()

    const handleFile = async (originalFile: File) => {
        const name = `${member.name} - headshot`

        const file = await resizeImage(originalFile, {})
        const response = (await uploadAvatar({ file, name })) as { id: string }
        if (response?.id) {
            const avatar = response.id.replace('avatar/', '')

            await updateExpertMapping({
                userSub: member.id,
                title: member.title || '',
                avatar,
                name: member.name,
            })

            await refetchMappings()
        }
    }
    return (
        <Box
            display="flex"
            width="260px"
            justifyContent="space-between"
            alignItems="center"
        >
            {member.avatar ? (
                <Image
                    src={
                        environment.apiGatewayUrl?.includes('localhost')
                            ? `${environment.apiGatewayUrl}/avatar/${member.avatar}`
                            : `${environment.frontendUrl}/avatar/${member.avatar}`
                    }
                    width="50px"
                />
            ) : (
                <Text>No avatar mapping</Text>
            )}
            <FileControl
                variant="ghost"
                buttonLabel="Update"
                accept=".jpg"
                onSelect={handleFile}
            />
        </Box>
    )
}

function AddMemberModal({
    id,
    isOpen,
    onClose,
    onConfirm,
}: {
    id: string
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}) {
    const [email, setEmail] = useState('')

    const [addUser] = usePostAddToCompetition()

    const addExpert = async () => {
        const res = await addUser({
            competitionId: id,
            email,
        })
        if (res === 200) {
            onConfirm()
        }
    }

    return (
        <StyledModal
            size="sm"
            isOpen={isOpen}
            onClose={onClose}
            footer={
                <>
                    <Button variant="outline" onClick={onClose} mr="4">
                        Cancel
                    </Button>
                    <Button colorScheme="blue" onClick={addExpert}>
                        Add
                    </Button>
                </>
            }
        >
            <Text mt="2">Add an expert to the Competition</Text>
            <Text mt="4">Enter an email address:</Text>
            <Input onChange={(e) => setEmail(e.target.value)} value={email} />

            <Text mt="4">
                Note: this will also add them to the season if they are not
                previously registered
            </Text>
        </StyledModal>
    )
}
