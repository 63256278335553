import { useReactOidc } from '@axa-fr/react-oidc-context'
import { User, UserManagerSettings, WebStorageStateStore } from 'oidc-client'
import { environment } from '../environments/environment'

export const AuthConfiguration: UserManagerSettings = {
    client_id: environment.clientId,
    redirect_uri: `${environment.baseUrl}/authentication/callback`,
    response_type: 'code',
    post_logout_redirect_uri: environment.baseUrl,
    scope: 'openid email profile swm:services',
    authority: environment.idp,
    silent_redirect_uri: `${environment.baseUrl}/authentication/silent_callback`,
    automaticSilentRenew: true,
    response_mode: 'query',
    userStore: new WebStorageStateStore({ store: localStorage }),
    staleStateAge: 43200, // 12 hours
}

export const permissions = {
    callCenter: 'the-game:admin:call-center',
    admin: 'the-game:admin:admin',
} as const

export type Permission = typeof permissions[keyof typeof permissions]

export interface AuthenticatedUser extends User {
    hasPermission: (schema: Permission) => boolean
}

export function useAuthenticatedUser(): AuthenticatedUser | null {
    const { oidcUser } = useReactOidc()

    if (!oidcUser) {
        return null
    }

    return {
        ...oidcUser,
        toStorageString: oidcUser.toStorageString,
        hasPermission: (schema: Permission) => {
            return (
                oidcUser.profile['swm:services:permissions'] &&
                oidcUser.profile['swm:services:permissions'].includes(schema)
            )
        },
    }
}
