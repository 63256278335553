import React, { useState } from 'react'
import {
    Box,
    Grid,
    GridItem,
    Text,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Input,
    IconButton,
    Tooltip,
} from '@chakra-ui/react'
import {
    ChevronRightIcon,
    EditIcon,
    LockIcon,
    UnlockIcon,
} from '@chakra-ui/icons'
import { Season, SeasonStatus } from '../data'
import { useConfigureSeason } from '../hooks/useConfigureSeason'
import { usePublishSeason } from '../hooks/usePublishSeason'
import { useLockSeason } from '../hooks/useLockSeason'
import { StyledModal } from '../components/StyledModal'
import { useGetRounds } from '../hooks/useGetRounds'
import { AddRoundsSection } from './AddRoundsSection'
import { ExpertTipperSection } from './ExpertTipperSection'
import { Fixtures } from './Fixtures'
import { SeasonPageName } from './SeasonForm'
import { ConfigureRounds } from './ConfigureRounds'
import { DocumentsAndPrizing } from './DocumentsAndPrizing'
import { ManagePrizing } from './ManagePrizing'
import { ManageWinners } from './ManageWinners'
import { ExpertTippers } from './ExpertTippers'
import { MdPublishedWithChanges } from 'react-icons/md'
import { ImageUpload } from '../components/ImageUpload'

function toTitleCase(sentence: string) {
    return sentence
        .replace('_', ' ')
        .split(' ')
        .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
        .join(' ')
}

export interface ManageSeasonBaseProps {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}

interface ManageSeasonProps extends ManageSeasonBaseProps {
    page?: SeasonPageName
}

export function ManageSeason({ season, page, refetch }: ManageSeasonProps) {
    const renderPage: Record<SeasonPageName, JSX.Element> = {
        add_rounds: <ConfigureRounds season={season} refetch={refetch} />,
        expert_tippers: <ExpertTippers season={season} refetch={refetch} />,
        prizing: <ManagePrizing season={season} refetch={refetch} />,
        winners: <ManageWinners season={season} refetch={refetch} />,
    }

    return (
        <Box px="10">
            <Box height={100} flexDir="row" display="flex">
                <Box flex="1">
                    <Breadcrumb
                        spacing="8px"
                        separator={<ChevronRightIcon color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/seasons">
                                Manage Seasons
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href={`/seasons/${season.sport}_${season.season}`}
                            >
                                {season.sport} ({season.season})
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        {page && (
                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink
                                    href={`/seasons/${season.sport}_${season.season}/${page}`}
                                >
                                    {toTitleCase(page)}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        )}
                    </Breadcrumb>

                    <Text fontSize={'xx-large'} mt="4">
                        {season.sport} ({season.season})
                    </Text>
                </Box>
                <SeasonIdInformation season={season} refetch={refetch} />
            </Box>
            <Box height="0.5px" backgroundColor="grey" />

            <Box mt="10" mb="10">
                {page ? (
                    renderPage[page]
                ) : (
                    <ConfiguringBase season={season} refetch={refetch} />
                )}
            </Box>
        </Box>
    )
}

function SeasonIdInformation({
    season,
    refetch,
}: {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}) {
    const [showConfig, setShowConfig] = useState(false)
    const [tabTouchCompId, setTabTouchCompId] = useState(
        season.config?.tabTouchCompId || '',
    )
    const [sendGridId, setSendGridId] = useState(
        season.config?.sendGridId || '',
    )
    const [configure] = useConfigureSeason()
    const submit = async () => {
        if (season.config) {
            await configure({
                season,
                config: {
                    ...season.config,
                    tabTouchCompId,
                    sendGridId,
                },
            })
            await refetch()
        }
        setShowConfig(false)
    }

    return (
        <Box display="flex" flexDir="row" alignItems="center">
            <Grid templateColumns="12rem 20rem" gap="2">
                <GridItem>
                    <Text>Sendgrid Template:</Text>
                </GridItem>
                <GridItem>
                    <Text>{season.config?.sendGridId}</Text>
                </GridItem>
                <GridItem>
                    <Text>
                        {season.sport !== 'Cricket'
                            ? 'ChampionData'
                            : 'StatsPerform'}{' '}
                        Comp Id:
                    </Text>
                </GridItem>
                <GridItem>
                    <Text>{season.config?.ingestionCompId}</Text>
                </GridItem>
                <GridItem>
                    <Text>TabTouch Comp Id:</Text>
                </GridItem>
                <GridItem>
                    <Text>{season.config?.tabTouchCompId}</Text>
                </GridItem>
            </Grid>
            <IconButton
                size="sm"
                aria-label="edit"
                variant="ghost"
                colorScheme="blue"
                onClick={() => setShowConfig(true)}
                icon={<EditIcon />}
            />

            <StyledModal
                isOpen={showConfig}
                onClose={() => setShowConfig(false)}
                size="lg"
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowConfig(false)}
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={submit} ml="4">
                            Update
                        </Button>
                    </Box>
                }
            >
                <Text size="large" pt="2" pb="5">
                    Update Season Config
                </Text>
                <Text>SendGrid Template</Text>
                <Input
                    value={sendGridId}
                    onChange={(e) => setSendGridId(e.target.value)}
                />
                <Text mt="4">TabTouch Competition Id</Text>
                <Input
                    value={tabTouchCompId}
                    onChange={(e) => setTabTouchCompId(e.target.value)}
                />
            </StyledModal>
        </Box>
    )
}

function ConfiguringBase({
    season,
    refetch,
}: {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}) {
    const [publishSeason] = usePublishSeason()
    const [lockSeason] = useLockSeason()
    const { data: roundData } = useGetRounds({
        sport: season.sport,
        season: season.season,
    })

    const [showPublishModal, setShowPublishModal] = useState(false)
    const [showLockModal, setShowLockModal] = useState(false)
    const [lockReason, setLockReason] = useState('')

    const validateSeason = () => {
        if (season.published) {
            return season.status === SeasonStatus.Completed
        } else {
            if (season.config === undefined) return false
            if (
                season.sport === 'Cricket' &&
                (!roundData?.rounds || roundData.rounds.length === 0)
            )
                return false

            return (
                season.config.prizing.length > 0 &&
                season.config.termsAndConditions !== ''
            )
        }
    }

    return (
        <>
            <DocumentsAndPrizing season={season} refetch={refetch} />

            <AddRoundsSection season={season} rounds={roundData?.rounds} />

            <ExpertTipperSection season={season} refetch={refetch} />
            <Box>
                <Text fontSize={'x-large'} fontWeight="bold" marginTop={8}>
                    Official Social Share Sponsor
                </Text>
                <Text fontSize={'sm'}>.png format, 100x100px</Text>
                <ImageUpload
                    fileName={`${season.sport}-socialsharesponsor.png`}
                ></ImageUpload>
            </Box>

            <Fixtures season={season} />

            <StyledModal
                size="lg"
                onClose={() => setShowPublishModal(false)}
                isOpen={showPublishModal}
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowPublishModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            ml="4"
                            colorScheme="red"
                            onClick={async () => {
                                await publishSeason({
                                    season: {
                                        ...season,
                                        published: !season.published,
                                    },
                                })
                                await refetch()
                                setShowPublishModal(false)
                            }}
                        >
                            {season.published ? 'Unpublish' : 'Publish'}
                        </Button>
                    </Box>
                }
            >
                <Box pt="5" display="flex" justifyContent="center">
                    <Text fontSize="large">
                        Are you sure you want to{' '}
                        {season.published ? 'unpublish' : 'publish'} this
                        season?
                    </Text>
                </Box>
                <Box pt="5" display="flex" justifyContent="center">
                    {season.published ? (
                        <Text fontSize="small" textAlign="center">
                            {`Ensure at least one other ${season.sport} season is published before unpublishing this one`}
                        </Text>
                    ) : undefined}
                </Box>
            </StyledModal>

            <Tooltip
                label={
                    season.published
                        ? 'Season must be complete before it can be unpublished'
                        : `Must have ${
                              season.sport === 'Cricket' && 'Rounds, '
                          } Terms & Conditions and a prize configured`
                }
                shouldWrapChildren
                isDisabled={validateSeason()}
            >
                <Button
                    mt="10"
                    mr="10"
                    colorScheme="blue"
                    onClick={() => setShowPublishModal(true)}
                    disabled={!validateSeason()}
                    leftIcon={<MdPublishedWithChanges />}
                >
                    {season.published ? 'Unpublish' : 'Publish'} Season
                </Button>
            </Tooltip>
            <StyledModal
                size="lg"
                onClose={() => setShowLockModal(false)}
                isOpen={showLockModal}
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowLockModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            ml="4"
                            colorScheme="red"
                            disabled={lockReason.length < 8}
                            onClick={async () => {
                                await lockSeason({ season, reason: lockReason })
                                await refetch()
                                setShowLockModal(false)
                            }}
                            leftIcon={
                                season.locked ? <UnlockIcon /> : <LockIcon />
                            }
                        >
                            {season.locked ? 'Unlock' : 'Lock'}
                        </Button>
                    </Box>
                }
            >
                <Box pt="5" textAlign="center">
                    <Text fontSize="large">
                        Reason for {season.locked ? 'unlocking' : 'locking'}{' '}
                        season?
                    </Text>
                    <Text fontSize="small">Minimum 8 characters</Text>
                </Box>
                <Input
                    mt={'5'}
                    title="Reason"
                    value={lockReason}
                    onChange={(e) => setLockReason(e.target.value)}
                />
            </StyledModal>
            <Button
                mt={'10'}
                colorScheme="blue"
                onClick={() => setShowLockModal(true)}
                leftIcon={season.locked ? <UnlockIcon /> : <LockIcon />}
            >
                {season.locked ? 'Unlock' : 'Lock'} Season
            </Button>
        </>
    )
}
